* {
  padding: 0;
  margin: 0;
}

body.bm-theme {
  height: 100%;
  min-height: 100%;
  font-family: Lato, sans-serif;
  overflow: auto;
  position: initial;

  @media (max-width: 767px) {
    .table {
      td, th {
        padding: .5rem .25rem!important;
      }
    }
  }

  @media print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  button {
    &.close:focus {
      outline-width: 0;
    }
  }

  h1 {
    font-size: 26px !important;
    @media (max-width: 575px) {
      font-size: 22px !important;
    }
  }

  h4 {
    font-size: 24px !important;
    @media (max-width: 575px) {
      font-size: 20px !important;
    }
  }

  .positive {
    color: $success !important;
  }

  .negative {
    color: $error !important;
  }

  .small-font {
    font-size: 12px;
  }

  .big-font {
    font-size: 18px;
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-right {
    text-align: right;
  }

  .side-nav {
    &__container {
      margin-top:1rem!important;
      background-color: $off-white;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .box-shadow-none {
    box-shadow: none !important;
  }

  .cdk-overlay-container {
    z-index: 100000 !important;
  }

  .shared-label {
    padding: 4px 8px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    max-width: 100px;
    background-color: $lightest-gray;
  }

  .bm-back-link__icon {
    color: #8b4d50;
    font-size: 12px;
  }

  .bm-back-link__text {
    margin-left: 3px;
  }

  a.bm-back-link {
    color: #8b4d50;
    font-size: .875rem;

    &:hover {
      color: #8b4d50;
      text-decoration: none;

      .bm-back-link__text {
        text-decoration: underline;
      }
    }

    &:focus {
      color: #8b4d50!important;
    }
  }

  .text-gray {
    color: #9c9c9c;
  }

  table.table {
    margin-bottom: 0;

    th {
      padding: 8px 5px;
      font-size: 12px;
      white-space: nowrap;
      text-transform: uppercase;

      .fa {
        position: relative;
      }

      .dropdown-toggle {
        padding: 0 4px;
      }

      &.col-check input {
        vertical-align: sub;
      }
    }

    td {
      vertical-align: inherit;
      font-size: 13px;
      padding: 5px;
    }

    a {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .btn-toggle,
    .dropdown-toggle {
      padding: 0 4px;
      border-radius: 4px;
      line-height: 15px;

      .fa {
        color: $darker-gray;
      }
    }

    .dropdown-item {
      text-decoration: none !important;

      &:active {
        color: #FFF;

        i {
          color: #FFF;
        }
      }
    }

    &-striped tbody tr {
      transition: background-color 0.2s linear;

      &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.025);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    @media (min-width: 768px) {
      .col-check {
        width: 20px;
      }
      .col-price {
        width: 95px;
      }
      .col-subcategory {
        width: 30%;
      }
      .col-date {
        width: 90px;
      }
      .col-status {
        width: 90px;
      }
      .col-amount {
        text-align: right;
        width: 80px;
      }
    }
  }

  .dropdown-item {
    cursor: pointer;
    color: $blue;
  }

  .tile-container {
    padding: 12px;
    font-size: 14px;
    vertical-align: top;
    display: inline-block;
    position: relative;
    border: 1px solid $lighter-gray;
    box-shadow: 0 3px 6px rgb(0 0 0 / 20%);

    &-title {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-value {
      font-size: 20px;
      font-weight: bold;
    }

    ul {
      list-style: none;
      margin: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .actions-container {
    margin: -10px 0 5px 0;
    min-height: 30px;

    .action-item {
      background: $blue;
      color: #FFF;
      text-transform: uppercase;
      display: inline-flex;
      margin: 0 10px 5px 0;
      height: 20px;
      padding: 0 10px;
      line-height: 20px;
      font-size: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        color: #FFF;
        opacity: 0.8;
      }

      &:active {
        box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
      }

      &.delete {
        background-color: $error;
      }

      &.approve {
        background-color: $primary;
      }

      &.send {
        background-color: $success;
      }

      &.resend {
        background-color: $tan;
      }

      &.print, &.download-report {
        background-color: $aqua;
      }
    }

    .action-button-disable {
      pointer-events: none !important;
      opacity: 75% !important;
    }
  }

  .invoice-status {
    &-draft {
      background: $draft;
    }

    &-unsent {
      background: $unsent;
    }

    &-sent {
      background: $sent;
    }

    &-processing_payment {
      background: $processing-payment;
    }

    &-partially_paid,
    &-active {
      background: $partially-paid;
    }

    &-paid {
      background: $paid;
      color: #FFF;
    }

    &-overdue,
    &-ended {
      background: $overdue;
      color: #FFF;
    }
  }

  .tabs-menu {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin: 15px 0;
    border-bottom: 1px solid $gray-300;

    &-item {
      flex: 0;
      white-space: nowrap;
      text-transform: uppercase;
      margin: 0 30px -1px 0;
      color: $blue;
      padding: 8px 0;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s linear;
      border-bottom: 3px solid transparent;

      &.selected {
        border-bottom-color: $dark-red;
      }

      &:hover {
        color: #000;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .mat-expansion-panel.accordion {
    margin-top: 20px;
    border-bottom: 1px solid $gray-300;
    box-shadow: none;
    border-radius: 0;

    & > .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        font-size: 24px;
        color: $gray-900;
      }

      .mat-content {
        align-items: center;
      }

      &:hover {
        background: none !important;
      }

      &[aria-disabled=true] {
        cursor: inherit;
      }
    }

    .mat-expansion-panel-content {
      font: inherit;
    }

    .mat-expansion-panel-body {
      padding: 0 0 10px 0;
      position: relative;
    }
  }

  .modal-backdrop {
    z-index: 1040!important;
  }
}

.app-table {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $off-white;

  &__placeholder {
    width: 70%;
  }
}
