@import "variables";

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: $error;
}

.mat-error {
  color: $error;
}

.mat-form-field.mat-warn .mat-input-element, .mat-form-field-invalid .mat-input-element {
  caret-color: $error;
}

.go-native {
  &.cdk-global-scrollblock {
    position: sticky!important;
  }
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  @media screen and ( max-width: 59.375em ) {
    overflow-y: auto;
    overscroll-behavior: none;
  }
}

.cdk-global-overlay-wrapper {
  @media screen and ( max-width: 59.375em ) {
    pointer-events: auto!important;
  }
}

.bm-mat-dialog {
  height: 100%;
  max-height: 600px;
  width: 100%;
  max-width: 900px;

  @media screen and ( max-width: 59.375em ) {
    width: 97%;
    height: unset;
    max-height: unset;
    min-height: 600px;
    position: absolute!important;
    top: 0;
    padding: 16px 0;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  mat-dialog-container {
    position: relative;
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    mat-dialog-content {
      padding: 0 24px 16px 24px;
    }
    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }

    @media screen and ( max-width: 59.375em ) {
      overflow: hidden;
      mat-dialog-content {
        overflow: hidden;
      }
    }
  }
}

.mat-checkbox {
  width: 100%;
}
.mat-checkbox-layout .mat-checkbox-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
