@import "variables";

.mat-button {
  font-family: "Open Sans", sans-serif;
}

.mat-button,
.mat-stroked-button,
.mat-flat-button {
  font-size: .875rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  color: $white;
  height: 36px;
  position: relative!important;
  overflow: hidden!important;
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  cursor: pointer;
}

.mat-button.mat-button-disabled, .mat-icon-button.mat-button-disabled, .mat-stroked-button.mat-button-disabled, .mat-flat-button.mat-button-disabled {
  cursor: not-allowed!important;
}


.mat-button.mat-primary,
.mat-flat-button.mat-primary {
  background-color: $red;
  &:hover, &:focus-visible {
    opacity: 0.9;
  }
}

.mat-stroked-button.mat-primary {
  color: $red;
  border-color: $red;
  background-color: $white;

  &:hover, &:focus-visible {
    background-color: $red;
    color: $white!important;
  }
}

.mat-button.mat-danger,
.mat-flat-button.mat-danger {
  background-color: $danger;
  &:hover, &:focus-visible {
    opacity: 0.9;
  }
}

.mat-stroked-button.mat-danger {
  color: $danger;
  border-color: $danger;
  background-color: $white;

  &:hover, &:focus-visible {
    background-color: $danger;
    color: $white!important;
  }
}

.mat-button.mat-outline-secondary,
.mat-flat-button.mat-outline-secondary {
  background-color: #6c757d;
  &:hover, &:focus-visible {
    background-color: #6c757d;
  }
}

.mat-stroked-button.mat-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  background-color: $white;

  &:hover, &:focus-visible {
    background-color: #6c757d;
    color: $white!important;
  }
}

.mat-standard-chip:focus::after {
  opacity: 0!important;
}
