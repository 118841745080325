@import "styles/custom.theme";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "styles/mat-autocomplete";
@import "styles/global";
@import "styles/datepicker";
@import "styles/forms";
@import "styles/mat-button-color";
@import "styles/mat-dialog";
@import "styles/mat-chip";
@import "styles/mat-tooltip";
@import "styles/material";
@import "font-awesome";
