// base colors
$white: #fff;
$black: #000;
$gray-300: #dee2e6;
$gray-900: #212529;
$input-color: $gray-900;

$blue: #184f72;
$danger: #dc3545;
$dark-danger: #c82333;
$red: #8b4d50;
$dark-red: #653739;
$orange: #df5123;
$light-red: #f6cec2;
$tan: #b47207;
$light-tan: #fce7c6;
$aqua: #197278;
$dark-aqua: #104a4e;
$green: #47800c;
$light-green: #d4e2c7;
$white: #fff;
$off-white: #f9f7f7;
$lightest-gray: #f4f4f3;
$lighter-gray: #e8e7e6;
$light-gray: #d8d6d5;
$gray: #bbb;
$dark-gray: #a39c9b;
$darker-gray: #847b79;
$darkest-gray: #655a58;

// semantic colors
$success: $green;
$alert: $tan;
$primary: $red;
$error: $orange;
$secondary: $orange;

$table-accent-bg: rgba($black, .05);
$border-color: $gray-300;

// status colors
$draft: $light-gray;
$unsent: #B3E4F5;
$sent: #FFFFF2;
$processing-payment: #FFFF99;
$partially-paid: #D3FFAB;
$paid: $green;
$overdue: $orange;
