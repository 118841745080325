@import "variables";

input {
  line-height: normal !important;
}

.mat-autocomplete-panel {
  .mat-option {
    height: 32px;
    line-height: 32px;
    padding: 0;

    .mat-option-text {
      padding: 0 10px;
      font-size: 13px;

      .mat-checkbox {
        display: inherit;

        .mat-checkbox-layout {
          margin: 0;
          width: 100%;
          padding: 0 10px;
          margin: 0 -10px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;

          .mat-checkbox-label {
            font-size: 13px;
          }

          .mat-checkbox-inner-container {
            height: 14px;
            width: 14px;
            margin-right: 5px;
            vertical-align: text-bottom;
          }

          .mat-checkbox-ripple {
            left: calc(50% - 15px);
            top: calc(50% - 15px);
            height: 30px;
            width: 30px;
          }
        }

        .mat-checkbox-checked .mat-checkbox-background {
          background-color: $blue;
        }
      }
    }
  }
}

.mat-icon-button {
  color: $blue;
  transition: color 0.3s linear;

  &:hover {
    color: #000;
  }

  &:focus {
    outline: none;
  }
}

.mat-form-field.dropdown {
  .mat-select-value-text {
    font-weight: bold;
  }

  .mat-form-field-label {
    color: #000;
    font-weight: bold;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.field-container, .field-container-row {
  display: flex;

  label {
    cursor: pointer;
    white-space: nowrap;
    line-height: 37px;
    font-size: 15px;
    font-weight: bold;
    margin: 0 10px 0 0;
  }

  .mat-form-field {
    width: 100%;
  }
}

.field-container-row {
  flex-flow: nowrap column;

  .field-label {
    text-transform: uppercase;
    font-size: 14px;
  }
}

.modal-dialog {
  .field-container {
    flex-flow: nowrap column;

    label {
      margin-top: -10px;
      line-height: 28px;
    }
  }
}

.mat-select-panel-wrap .mat-select-panel {
  .mat-option {
    line-height: 32px;
    height: 32px;

    &:hover {
      background: $off-white;
    }

    &.mat-active:not(.mat-option-disabled),
    &.mat-selected:not(.mat-option-disabled) {
      color: $blue;
      font-weight: bold;
      background: $lighter-gray;
    }
  }
}

.mat-form-field.mat-form-field-appearance-outline {
  font-size: 15px;
  .mat-form-field-infix {
    padding: 0;
    border: 0;
    width: 160px;

    input {
      height: 38px;
      margin: 0;
    }

    textarea {
      padding: 10px 0;
      height: 80px;
    }

    .mat-select-trigger {
      height: 38px;
      line-height: 38px;

      .mat-select-arrow-wrapper {
        transform: none;
      }
    }
  }

  .mat-form-field-flex {
    padding: 0 0.375rem;
    align-items: center;
    background: #FFF;

    .mat-form-field-prefix {
      top: inherit;
      background: #e9ecef;
      border-right: 1px solid $gray-300;
      display: flex;
      height: 36px;
      margin-right: 5px;
      padding: 0 12px;
      margin-left: -5px;
      border-radius: 4px 0 0 4px;
      align-items: center;
      transition: all 0.3s linear;
    }
  }

  .mat-form-field-outline {
    color: $gray-300;
    top: 0;
    transition: color 0.4s ease-in-out;
  }

  .mat-form-field-outline-thick {
    color: $blue;
    z-index: 1;
  }

  .mat-chip-list {
    .mat-chip-list-wrapper {
      margin: 4px 0;

      input {
        height: 30px;
      }

      .mat-chip-remove {
        margin-left: 5px;
      }
    }

    input.mat-input-element {
      margin: 0;
      width: 100px;
      flex: 1 0 100px;
    }
  }

  .mat-chip-with-trailing-icon {
    padding: 0 5px;
    margin: 2px;
    min-height: 24px;
    font-size: 12px;
    border-radius: 4px;

    & > span {
      overflow: hidden;
      max-width: 100px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .mat-form-field-wrapper {
    .mat-form-field-suffix {
      .mat-icon-button {
        font-size: 13px;
        float: right;
        margin-right: -5px;
        width: 30px;
        height: 30px;
        top: -4px;
      }
    }
  }

  .mat-form-field-subscript-wrapper {
    padding: 0 5px;
    margin-top: 3px;
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex {
      background: #e9ecef;
      border-radius: 7px;

      .mat-input-element {
        color: $gray-900;
      }
    }
  }
}

.field-error {
  color: $orange;
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 11px;
}

.form-control,
.mat-input-element {
  &::placeholder {
    color: $dark-gray;
  }
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.md-drppicker {
  .btn.clear {
    margin-right: 10px;
    font-size: 12px;
    color: $blue;
    transition: color 0.3s linear;

    svg {
      height: 16px;
      width: 16px;
      color: $blue;
      margin-top: -10px;
      transition: color 0.3s linear;
    }

    &:hover {
      color: #000;
      svg {
        color: #000;
      }
    }
  }
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.form-group label {
  font-weight: bold;
  cursor: pointer;
}
