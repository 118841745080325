.mat-chip-list-wrapper input.mat-input-element, .mat-chip-list-wrapper .mat-standard-chip {
  margin: 3px!important;
}

.mat-chip.mat-standard-chip {
  background-color: #EAEAEA!important;
  color: #655a58;
}

.mat-form-field.mat-form-field-appearance-outline .mat-chip-with-trailing-icon {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
