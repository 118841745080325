@import "variables";

.bm-theme {
  // bootstrap start
  .btn {
    font-size: .875rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .show > .btn-primary.dropdown-toggle {
    &:focus,
    &:active,
    &:hover {
      background-color: $red;
      border-color: $red;
      color: #fff;
    }
  }

  .btn-primary {
    background-color: $red;
    border-color: $red;
    color: #fff;

    &.hover, &:hover, &:focus-visible {
      background-color: $dark-red;
      border-color: $dark-red;
      color: #fff;
    }

    &:not(:disabled):not(.disabled) {
      &.active, &:active {
        background-color: $dark-red;
        border-color: $dark-red;
      }
    }

    &.disabled, &:disabled {
      background-color: $dark-red;
      border-color: $dark-red;
      color: #fff;
    }
  }

  .btn-outline-primary {
    border-color: $red;
    color: $red;

    &:hover, &:active, &:focus-visible {
      background-color: $red;
      border-color: $red;
      color: #fff;
    }

    &:not(:disabled):not(.disabled) {
      &.active, &:active {
        background-color: $dark-red;
        border-color: $dark-red;
      }
    }

    &.disabled, &:disabled {
      border-color: $dark-red;
      color: $dark-red;

      &:hover {
        background-color: transparent;
        border-color: $dark-red;
        color: $dark-red;
      }
    }
  }

  .text-primary {
    color: $red !important;
  }

  .text-secondary {
    color: $blue;
  }

  .btn-link {
    color: $blue;

    &:hover {
      color: $blue;
    }
  }

  a {
    color: $blue;

    &.text-primary {
      &:hover, &:focus {
        color: $red !important;
        cursor: pointer;
        text-decoration: none;
      }
    }

    &:hover {
      color: $blue;
    }
  }

  .form-control {
    padding: .375rem;
    font-size: 15px;
    min-height: 38px;
    border-color: $gray-300;
    transition: all 0.3s ease-in-out;

    &:not([disabled]):focus,
    &:not([disabled]):hover {
      border-color: $blue;
      box-shadow: 0 0 0 1px $blue;
    }
  }
}
