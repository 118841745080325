@import "variables";

$mat-option-element-height: 48px;

.mat-autocomplete-panel-with-fixed-bottom-element {
  position: static!important;
  min-width: 160px;
  margin-bottom: $mat-option-element-height;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  .fixed-bottom-element {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $mat-option-element-height;
    line-height: $mat-option-element-height;
    padding: 0 16px;
    background-color: $white;
    text-align: center;
    cursor: pointer;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
  }
}

