$weekdays: (1: Sun, 2: Mon, 3: Tue, 4: Wed, 5: Thu, 6: Fri, 7: Sat);

.invoice-period .md-drppicker.shown {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 280px;

  .ranges ul {
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 0 0 125px;
      margin: 0!important;
    }
  }

  @media (min-width: 610px) {
    flex-direction: row;
    width: 565px;
    .ranges {
      width: 100%;
    }
    .ranges ul {
      li {
        flex: 0 0 150px;
      }
    }
    .buttons {
      position: absolute;
      top: 5px;
      right: 0;
      text-align: left;
    }
  }

  @media (min-width: 1100px) {
    width: 665px;
    .ranges {
      width: 100px;
      ul {
        flex-direction: column;
        li {
          flex: 1 0 auto;
        }
      }
    }
    .buttons {
      width: 100%;
      position: static;
    }
  }
}

// Datepickers
.md-drppicker {
  color: $darker-gray!important;
  &.shown {
    @media (min-width: 610px) {
      top: 32px !important;
      left: auto !important;
      right: -5px !important;
    }
  }
  .calendar {
    max-width: 270px;
    td, th {
      border-radius: 2px!important;
      font-weight: bold;
      color: $red!important;
      opacity: 1;
      &.week {
        font-weight: normal;
        font-style: italic;
      }
      &.off {
        color: $darker-gray!important;
      }
      &.disabled {
        text-decoration: line-through;
        opacity: .7;
      }
      &.available {
        &:hover {
          background-color: $red!important;
          color: $white!important;
        }
        &.next,
        &.prev {
          color: $red!important;
          font-weight: bold;
          &:hover {
            background-color: $lighter-gray!important;
          }
        }
      }
      &.active {
        background-color: $blue!important;
        color: $white!important;
      }
    }
    thead tr {
      &:nth-child(1) {
        th {
          cursor: default;
          &.prev,
          &.next {
            cursor: pointer;
          }
        }
        th:nth-child(2) {
          position: relative;
          left: -30px;
        }
        th:nth-child(3) {
          text-transform: uppercase;
          position: relative;
          left: -15px;
        }
      }
      &:nth-child(2) {
        th {
          width: 280px;
          span {
            display: none;
          }
          &:after {
            font-weight: normal;
          }
          @each $i, $day in $weekdays {
            &:nth-child(#{$i + 1}):after {
              color: $darker-gray!important;
              content: '#{$day}';
            }
          }
        }
      }
    }
  }

  .ranges ul li button {
    font-weight: bold;
    color: $darkest-gray;
    &.active {
      background-color: $blue!important;
      color: $white!important;
    }
  }

  .buttons {
    .btn {
      background-color: $red;
      &:hover {
        background-color: $dark-red;
      }
    }
  }
}

.due-date .md-drppicker.shown {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 280px;
  .ranges ul {
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 0 0 50%;
    }
  }

  @media (min-width: 1100px) {
    flex-direction: row;
    width: 415px;
    .ranges {
      width: 130px;
      ul {
        flex-direction: column;
        li {
          flex: 1 0 auto;
        }
      }
    }
  }
}
